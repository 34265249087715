import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {Helmet} from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
  
}));

function HomeView() {
  
  const classes = useStyles();

  return (
    <div>
      <div className="application">
          <Helmet>
              <meta charSet="utf-8" />
              <title>dane++</title>
          </Helmet>
      </div>
      <div className={classes.root}>
        <Paper elevation={0} >
          <Typography variant="h6">
            D
          </Typography>
        </Paper>
        <Paper elevation={1}>
          <Typography variant="h6">
            A
          </Typography>
        </Paper>
        <Paper elevation={2}>
          <Typography variant="h6">
            N
          </Typography>
        </Paper>
        <Paper elevation={3}>
          <Typography variant="h6">
            E
          </Typography>
        </Paper>
      </div>
    </div>
  );
}

export default HomeView;