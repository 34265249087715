import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import logo2 from '../static/images/istockphoto-1155297185-1024x1024.jpg'
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {Helmet} from "react-helmet";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
   },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    demo: {
        marginTop: 'auto',
        marginBottom: 'auto',
        height: 350,
    },
    rootCard: {
        maxWidth: 345,
      },
      media: {
        height: 150,
      },
}));

 
function SumSquareView() {
    
    const classes = useStyles();
    const [num1, setNum1] = React.useState(0);
    const [num2, setNum2] = React.useState(0);
    const [answer, setAnswer] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [openMessage, setOpenMessage] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState();

    React.useEffect(() => {

        console.log(`in effects open: ${open}`);
        
        if (openMessage) {
            setOpen(true);
            setOpenMessage(false)
            console.log(`in effects openeMessage: ${openMessage}`);
            setTimeout(() => {
                setOpen(false)
                console.log('This will run after 1 second!')
              }, 2000)

        }                      
    },[successMessage, open, openMessage] )

    const handleMessage = async (n) => {
        console.log(`handleMessage for number ${n}`)
        setOpenMessage(true)
        setSuccessMessage(`${n} is power of 2`)
    }

    const test = () => {
        setOpenMessage(false)
    }

   // Recurssive function to calculate powe
    const powerOf2 = (n) => {
        if (!(Number.isInteger(n)))
            return false;
        if (n === 2 || n === 1)// remember 1 is also a power of 2
            return true
        else if (n > 2)
            return powerOf2(n/2)
        else
            return false;
    }

    const calculateSumSquare = (n1, n2) => {
        if (typeof n1 == "number" && typeof n2 == "number"){
            var s = Math.pow(n1, 2) + Math.pow(n2, 2);
            setAnswer(s);
        } else {
            console.log("at least one of the input is not a number.");
        }

        if (powerOf2(s)) {
            console.log(`${s} is power of 2`)
            handleMessage(s);
        }
    }

    const num1Change = n => {
        console.log(`num1Change: ${n}`)

        setNum1(+n);
        calculateSumSquare(+n, num2);
    }

    const num2Change = n => {
        console.log(`num2Change: ${n}`)

        setNum2(+n);
        calculateSumSquare(num1, +n);
    } 

    return (
        
       <div>
            <div className="application">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>dane++ | square sum</title>
                </Helmet>
            </div>
            <Grid container className={classes.root} paddingTop="20px">
                <Grid item xs={12}>
                    <Grid
                        container
                        spacing={3}
                        className={classes.demo}
                        alignItems="center"
                        direction="row"
                        justify="center"
                    >
                        <Card className={classes.rootCard}>
                            <CardActionArea>
                                    <CardMedia
                                        className={classes.media}
                                        src={logo2}
                                        component="img"
                                        //height="450"
                                        title="Squares"
                                    />
                                    <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Sum of Squares
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Input values for num1 and num2, they are squared then summed together to output the "Answer".
                                        If Answer is a power of 2, we will let you know :)    
                                    </Typography>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions>
                            </CardActions>
                        </Card>
                    </Grid>    
                </Grid>        
            </Grid>     
            <Grid container className={classes.root}>
                <Grid item xs={12}>
                    <Grid
                        container
                        spacing={3}
                        className={classes.demo}
                        alignItems="center"
                        direction="row"
                        justify="center"
                    >
                        <Grid item xs={3}>
                            <Paper className={classes.paper}>
                                <TextField id="num1" variant="outlined" label="num1" type="number" onChange={(e) => num1Change(e.target.value)} defaultValue={num1}/>
                            </Paper>
                        </Grid>
                        <Grid item xs={3}>
                            <Paper className={classes.paper}>
                                <TextField id="num2" variant="outlined" label="num2" type="number" onChange={(e) => num2Change(e.target.value)} defaultValue={num2}/>
                            </Paper>
                        </Grid>
                        <Grid item xs={3}>
                            <Paper className={classes.paper}>
                                <TextField disabled variant="outlined" id="standard-disabled" label="Answer" value={answer} />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Snackbar 
                open={open} 
                //autoHideDuration={10000} 
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                //onClose={test}
            >
                <Alert onClose={test} severity="success">
                    {successMessage}
                </Alert>
            </Snackbar>
        </div>      
    );
}

export default SumSquareView;